.links {
  color: var(--white);
}

.drawer-button {
  display: none;
}

@media only screen and (max-width: 600px) {
  .drawer-button {
    display: block;
  }
  .toolbar {
    display: none;
  }
}
