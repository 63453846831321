.login-container {
  overflow: hidden;
  margin: 0;
  height: 100vh;
  width: 100%;
  background: #007fd5;
  -webkit-animation: sky_background 50s ease-out infinite;
  -moz-animation: sky_background 50s ease-out infinite;
  -o-animation: sky_background 50s ease-out infinite;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
}

.contentback {
  padding-top: 10vh;
}

.formcontent {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.sky {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.logo {
  display: block;
  margin: auto;
  max-width: 500px;
  width: 90%;
}

.clouds-one {
  background: url("/img/cloud_one.png");
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 300%;
  -webkit-animation: cloud_one 50s linear infinite;
  -moz-animation: cloud_one 50s linear infinite;
  -o-animation: cloud_one 50s linear infinite;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
}

.clouds-two {
  background: url("/img/cloud_two.png");
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 300%;
  -webkit-animation: cloud_two 75s linear infinite;
  -moz-animation: cloud_two 75s linear infinite;
  -o-animation: cloud_two 75s linear infinite;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
}

.clouds-three {
  background: url("/img/cloud_three.png");
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 300%;
  -webkit-animation: cloud_three 100s linear infinite;
  -moz-animation: cloud_three 100s linear infinite;
  -o-animation: cloud_three 100s linear infinite;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
}

@keyframes sky_background {
  0% {
    background: #007fd5;
    color: #007fd5;
  }
  50% {
    background: #000;
    color: #a3d9ff;
  }
  100% {
    background: #007fd5;
    color: #007fd5;
  }
}

@keyframes cloud_one {
  0% {
    left: 0;
  }
  100% {
    left: -200%;
  }
}

@keyframes cloud_two {
  0% {
    left: 0;
  }
  100% {
    left: -200%;
  }
}

@keyframes cloud_three {
  0% {
    left: 0;
  }
  100% {
    left: -200%;
  }
}

.login-button {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}
