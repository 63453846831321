body {
  background-color: grey;
}
.factura-wrapper,
.inner-wrapper {
  width: 100%;
}
.factura-wrapper {
  height: 100vh;
}
.header,
.footer {
  width: 740px;
  background-color: darkblue;
  height: 30px;
}
.footer {
  position: absolute;
  bottom: 30px;
}
.title {
  margin-bottom: 5px;
}
.factura {
  width: 800px;
  min-height: 100%;
  position: relative;
  margin: 30px;
  padding: 30px 30px 70px 30px;
  background-color: white;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
.image {
  position: absolute;
  right: 0;
}
.first-block {
  display: flex;
  height: 200px;
}
.second-block,
.third-block {
  margin-bottom: 30px;
}
.detalles tr {
  width: 100%;
  height: 30px;
}
.factura-table,
.detalles {
  width: 100%;
}
.factura-table td {
  border: none !important;
  padding: 0 !important;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.separator {
  width: 100%;
  background-color: black;
  height: 2px;
}
.total {
  font-size: 40px;
  font-weight: 600;
}
.total > td {
  padding: 1rem 0 !important;
}
.subtotal > td {
  padding-top: 20px;
}
.print-button {
  position: absolute;
  left: 30px;
  top: 15px;
}
@media screen {
  .printControlls {
    display: block;
  }
}

@media print {
  body {
    background-color: white;
  }
  .header,
  .footer,
  .factura {
    width: 100%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .footer {
    position: fixed;
    bottom: 0;
  }
  .printControlls {
    display: none;
  }
  .factura {
    padding: 0;
    margin: 0;
  }
}
