.animales-list-form-add-button {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 20px;
  padding-top: 20px;
}

.animales-list-expansion-details {
  padding: 10px 0 !important;
}

.animales-list-remove-item {
  display: flex;
  align-items: center;
}
