.form-title {
  padding: 0 8px;
}

.form-wrapper {
  display: flex;
  margin: 20px;
  justify-content: center;
}

.form-line {
  padding: 0 8px;
  display: flex;
  align-items: flex-end;
}

.contacto-form-line {
  padding: 0 8px;
  display: flex;
  align-items: center;
}

.form-line > div,
.contacto-form-line > div {
  margin: 8px;
}

.radio-group {
  flex-direction: row !important;
}

.radio-group-wrapper {
  margin: 8px !important;
}

.complete-line {
  width: calc(100% - 16px);
}

.stepper {
  background-color: yellow;
}

.inline-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 8px;
}

.inline-row .search {
  display: block;
  margin-top: 0 !important;
}

.form-numeric-field {
  max-width: 45%;
}

.inline-action-row {
  display: flex;
  justify-content: flex-end;
  padding: 0 8px;
}

.actions-row {
  display: flex;
  justify-content: space-around;
}

.success-message div {
  background-color: green !important;
}

.success-message .icon {
  font-size: 20px;
  margin-right: 8px;
}

.success-message .message {
  display: flex;
  align-items: center;
  width: 100%;
}

.multi-select {
  width: 100%;
}

.chips {
  display: flex;
  flex-wrap: wrap;
}

.chip {
  margin: 2px;
}

.servicio-button > span {
  font-size: 10px;
}
