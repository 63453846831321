.custom-modal {
  margin: auto;
  margin-top: 20vh;
  max-width: 400px;
  width: 80%;
  align-items: center;
  display: grid;
}

.custom-modal-title {
  text-align: center;
}
