.table-wrapper {
  width: 100%;
  justify-content: space-between;
}
.paper {
  width: 100%;
}
.tableWrapper {
  overflow-y: scroll;
  overflow-x: scroll;
  width: 100%;
}
.title {
  flex: 0 0 auto;
}
.base-table-header {
  background-color: #eee;
}
.extra-header-component > div {
  margin: 0 0 auto;
}
.base-table-actions-cell {
  padding: 0 !important;
  display: inline-flex !important;
  border-bottom: 0 !important;
}

.base-table-row:first-child .base-table-actions-cell {
  border-top: 0 !important;
}

.base-table-row:last-child .base-table-actions-cell {
  padding-bottom: 1px !important;
  border-bottom: 1px solid #dee2e6 !important;
}

/* .base-table-search {
  position: absolute;
  right: 30px;
} */

.base-table-search-icon {
  width: 7px;
  height: 100%;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.base-table-clear-icon {
  width: 7px;
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.base-table-inputRoot {
  color: inherit;
}
.base-table-inputInput {
  width: 100%;
}
.empty-rows {
  height: 49px;
}
.empty-rows > td {
  border: 0;
}
