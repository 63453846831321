.wrapper {
  width: 100%;
}

.loading {
  width: 100%;
  padding-top: 40vh;
  display: flex;
}
.loading > div {
  margin: auto;
}
.add-button-wrapper {
  position: absolute;
  top: 5px;
  right: 30px;
}
.add-button {
  background-color: var(--green) !important;
}
.contact-button {
  margin-top: -5px !important;
}
